<template>
  <div class="container is-fluid text-left h-screen pr-0">
    <div class="columns h-full my-0" v-if="config">
      <div class="column is-3 h-full relative">
        <div>
          <p class="text-4xl font-bold mt-40">Inicia sesión</p>
          <p>
            ¿Nuevo usuario?
            <a
              :href="config.host + '/' + config.version + '/signup'"
              class="has-text-link"
            >
              Registrate</a
            >
          </p>
          <div class="mr-8 mt-8">
            <validation-observer
              ref="observer"
              v-slot="{ validate }"
              class="w-full text-left"
            >
              <ValidInput
                rules="email|required"
                label="Correo electrónico"
                v-model="form.email"
                label-class="has-text-grey-light font-light"
                type="email"
              />
              <ValidInput
                rules="required"
                label="Contraseña"
                v-model="form.password"
                label-class="has-text-grey-light mt-6 font-light"
                type="password"
              />
              <a
                class="text-right has-text-grey-darker float-right text-sm"
                :href="config.host + '/' + config.version + '/forgotten'"
              >
                ¿Olvidaste tu contraseña?
              </a>
              <b-button
                type="is-primary"
                class="mt-12 block"
                expanded
                @click="validate().then(res => login(res))"
                >Iniciar sesión</b-button
              >
            </validation-observer>
          </div>
        </div>
        <div class="columns absolute bottom-0 w-full mb-3 text-center">
          <!-- <div class="column has-text-grey-darker text-sm">
            <a href="">¿Necesitas ayuda?</a>
          </div> -->
          <div class="column has-text-grey-darker text-sm">
            Un producto de uVícate
          </div>
        </div>
      </div>
      <div
        class="column h-full is-9 bg-gradient-primary-secondary right-side-login relative"
      >
        <b-image
          class="absolute w-20 right-0 mr-20"
          src="/images/logo_white_symbol.png"
          alt="logo capvital"
        />
        <div class="flex flex-col items-center h-full justify-center">
          <b-image
            src="/images/login_img.svg"
            alt="login image mockup"
            class="mx-auto mb-0 mt-24"
          />
          <div class="text-center mb-20 mt-6">
            <p class="has-text-white text-4xl font-bold">
              Toda tu operación de cartera crediticia
            </p>
            <p class="has-text-white text-lg">En un solo lugar</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import config from "@/../config";
import ValidInput from "@/components/form/ValidInput";
import { ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      config: null,
      form: {
        email: null,
        password: null,
        authorize: "Authorized",
        outside: true
      }
    };
  },
  components: {
    ValidInput,
    ValidationObserver
  },
  computed: {
    ...mapGetters("loader", ["loading"])
  },
  methods: {
    login(res) {
      if (!res) return;
      this.$store.dispatch("login", this.form).then(() => {
        this.$router.go(-1);
      });
    }
  },
  watch: {
    loading() {
      this.$store.commit("loader/SET_CERO");
    }
  },
  mounted() {
    this.config = config;
  }
};
</script>

<style>
.right-side-login {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
</style>
